body {
  margin: 0;
  font-family: "Cairo", sans-serif;
  font-size: 24px;
}

p {
  direction: rtl;
}

.password {
  margin: 10px;
  text-align: center;
}
input {
  max-width: 300px;
  padding: 10px;

  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

label {
  font-size: 12px;
  display: block;
}
