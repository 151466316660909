* {
  box-sizing: border-box;
}

/* ---- grid ---- */

.grid {
  /* background: #eee; */
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

/* clearfix */
.grid:after {
  content: "";
  display: block;
  clear: both;
}

/* ---- grid-item ---- */

.grid-sizer,
.grid-item {
  width: 20%;
}

.grid-item {
  height: 200px;
  float: left;
  /* background: #d26; */
  border: 2px solid rgb(136, 48, 48);
  border-color: hsla(0, 0%, 0%, 0.5);
  /* border-radius: 5px; */
}

.grid-item--width2 {
  width: 40%;
}

.grid-item--width3 {
  width: 60%;
}
.grid-item--width100 {
  width: 100%;
}

@media only screen and (max-device-width: 400px) {
  /* Styles */
  .grid-item,
  .grid-sizer,
  .grid-item--width2,
  .grid-item--width3 {
    width: 100%;
  }
}
.grid-item--height2 {
  height: 300px;
}
.grid-item--height3 {
  height: 360px;
}
.grid-item--height4 {
  height: 460px;
}
.grid-item--height5 {
  height: 760px;
}

.text-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}
.text-box {
  text-align: center;
  direction: rtl;
}

@media only screen and (max-device-width: 400px) {
  .text-box {
    font-size: 18px;
  }
}

.image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.message {
  text-align: center;
  width: 100%;
  margin: 20px;
}
.message-bold {
  font-weight: bolder;
  font-size: 30px;
}
.message.bottom {
  margin-top: 10px;
  font-size: 16px;
  color: #7e7b7b;
}

input {
  margin-left: auto;
  margin-right: auto;
}
